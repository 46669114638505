import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import icon from './../assets/icons/logo.svg';
import Avatar from '../components/common/Avatar';
import gold from './../assets/icons/gold-tier.svg';
import silver from './../assets/icons/silver-tier.svg';
import bronze from './../assets/icons/bronze-tier.svg';
import FormDropdown from '../components/common/form/FormDropdown';

const Header = ({ title, type, headerButton, headerButtonType, headerButtonUrl, headerButtonTitle, project, categories, phases, openModal, clientTier, assignee, handleAssignee, selectedProject, handleProject }) => {
  const [loading, setLoading] = useState(true);
  const [taskAssignees, setTaskAssignees] = useState([]);
  const [taskProjects, setTaskProjects] = useState([]);

  const setPageData = useCallback(async () => {
    setLoading(true);
    let isMounted = true;
    if (isMounted) {
      let endpoints = [
        `/api/all-users`,
        `/api/all-projects`
      ];
      Promise.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
        ([
          { data: taskAssignees },
          { data: taskProjects },
        ]) => {
          if (taskAssignees.status === 200) {
            setTaskAssignees(taskAssignees.users);
          }
          if (taskProjects.status === 200) {
            setTaskProjects(taskProjects.projects);
          }
          setLoading(false);
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if(type === 'variation-5'){
      setPageData();
    }
  }, [type, setPageData]);

  const getDropdownOptions = (dataList) => {
    return dataList.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  };

  let HeaderComponent = '';
  let clientTierIcon = '';

  if (clientTier === 'gold') {
    clientTierIcon = gold;
  } else if (clientTier === 'silver') {
    clientTierIcon = silver;
  } else {
    clientTierIcon = bronze;
  }

  if (type === 'variation-1') {
    HeaderComponent = <div className="container-fluid p-0 variation-1">
      <div className='content-wrp'>
        <h1>{title}</h1>
      </div>
    </div>
  } else if (type === 'variation-2') {
    HeaderComponent = <div className="container-fluid p-0 variation-2">
      <div className='content-wrp'>
        <div className='project-name-wrp'>
          <h1>{title}</h1>
        </div>
        <div className='btn-wrp'>
          {headerButton && headerButtonType === "button" ?
            <Link className="btn btn-primary" to={headerButtonUrl}>{headerButtonTitle}</Link>
            :
            <button className="btn btn-outline-success btn-sm ms-1" onClick={openModal}><i className="fa fa-pencil"></i></button>
          }
        </div>
      </div>
    </div>
  }  else if (type === 'variation-3') {
    HeaderComponent = <div className="container-fluid p-0 variation-3">
      <div className='content-wrp'>
        <div className='project-logo-name-wrp'>
          <div className='project-logo'>
            <Avatar
              src={project.logo ? `${process.env.REACT_APP_BACKEND_URL}/${project.logo}` : icon}
              alt={project.name}
              className='avatar-large'
            />
          </div>
          <h1 className='project-name'>{ title }</h1>
        </div>
        <div className='project-content'>
          <div className='tagline-wrp'>
            {/* {categories.length > 0 && <div className='categories-wrp tagline'>{ categories.map(category => <span id={category.id} className='category-item status warning ex-small'>{category.label}</span>) }</div>}
            {phases.length > 0 && <div className='phases-wrp tagline'>{ phases.map(phase => <span id={phase.id} className='phase-item status warning ex-small'>{phase.label}</span>) }</div>} */}
            {project.project_status && <span className='status-item status blue ex-small'>{ project.project_status.name }</span>}
          </div>
          {headerButton && headerButtonType === "button" ?
            <Link className="btn btn-primary" to={headerButtonUrl}>{headerButtonTitle}</Link>
            :
            <button className="btn btn-edit" onClick={openModal}></button>
          }
        </div>
      </div>
    </div>
  } else if (type === 'variation-4') {
    HeaderComponent = <div className="container-fluid p-0 variation-4">
      <div className='content-wrp'>
        <div className='project-logo-name-wrp'>
          <h1 className='project-name'>{ title }</h1>
          {clientTier &&
            <div className='tier-wrp'>
              <img src={clientTierIcon} alt="clienttier" />
            </div>
          }
        </div>
        <div className='project-content'>
          <button className="btn btn-edit" onClick={openModal}></button>
        </div>
      </div>
    </div>
  } else if (type === 'variation-5') {
    HeaderComponent = <div className="container-fluid p-0 variation-1">
      <div className='content-wrp'>
        <h1>{title}</h1>
      </div>
      <div class="col-md-6 row g-2">
        <div className="col-md-6">
          <FormDropdown
            value={selectedProject}
            name="project_id"
            id="project_id"
            options={[
              { value: "all", label: 'All' },
              ...getDropdownOptions(taskProjects)
            ]}
            isSearchable={true}
            isDisabled={loading}
            onChange={handleProject}
            placeholder="Project"
          />
        </div>
        <div className="col-md-6">
          <FormDropdown
            value={assignee}
            name="assignee_id"
            id="assignee_id"
            options={[
              { value: "all", label: 'All' },
              ...getDropdownOptions(taskAssignees)
            ]}
            isSearchable={true}
            isDisabled={loading}
            onChange={handleAssignee}
            placeholder="Assignee"
          />
        </div>
      </div>
    </div>
  }
  return (
      <nav className="navbar navbar-frontend navbar-expand-lg sticky-top">
        {HeaderComponent}
      </nav>
  );
}

export default Header;